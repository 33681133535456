import { convertToMap } from "../../utilities/scripts";

export class Distribution {
  private _chart: Element;
  private _breakdown: Element;

  private _chartShares: Map<string, Element>;
  private _breakdownShares: Map<string, Element>;

  private _hadUserInteraction: boolean = false;

  public constructor(chart: Element | null | undefined, breakdown: Element | null | undefined) {
    if (chart == null) { throw Error(`chart: ${chart} is not an Element`); }
    if (breakdown == null) { throw Error(`breakdown: ${breakdown} is not an Element`); }

    this._chart = chart;
    this._breakdown = breakdown;

    const $chartShareArray = Array.from(this._chart?.querySelector("svg #segments")?.children ?? []);
    const $breakdownShareArray = Array.from(this._breakdown?.children ?? []);

    for (const $share of $chartShareArray.concat($breakdownShareArray)) {
      if (!($share instanceof Element)) { continue; }

      $share.addEventListener("click", (event) => {
        if (!(event.currentTarget instanceof Element)) {
          throw new Error(`event.target: ${event.currentTarget} is not an Element`);
        }

        this._hadUserInteraction = true;

        this.setAllSharesNotActive();

        let id;

        if (event.currentTarget.id) {
          id = event.currentTarget.id;
        } else {
          if (!(event.currentTarget.previousElementSibling instanceof Element)) {
            throw new Error(
              `event.target.previousElementSibling:`
                + `${event.currentTarget.previousElementSibling} is not an Element`);
          }

          id = event.currentTarget.previousElementSibling.id;
        }

        this.setShareAsActive(id);
      });
    }

    this._chartShares = convertToMap($chartShareArray, ($share) => $share.id);
    this._breakdownShares = convertToMap($breakdownShareArray, ($share) => $share.id);
  }

  private get shareIds(): Iterable<string> {
    const chartShareIds = Array.from(this._chartShares.keys());
    const breakdownShareIds = Array.from(this._breakdownShares.keys());

    return new Set(chartShareIds.concat(breakdownShareIds)).values();
  }

  private get hasUserInteraction(): boolean {
    const selectors = `.${this._chart.className}:hover,`
      + `.${this._breakdown.className}:focus-within,`
      + `.${this._breakdown.className}:hover`;

    return this._chart?.parentElement?.querySelector(selectors) != null;
  }

  public static fromObject(
    { chart, breakdown }: { chart: Element | null | undefined, breakdown: Element | null | undefined },
  ) {
    return new Distribution(chart, breakdown);    
  }

  public playDistributionAffordance({ duration = 2500 }: { duration?: number } = {}) {
    const shareIds = Array.from(this.shareIds);

    const delay = shareIds.length > 0
      ? duration / shareIds.length
      : duration;

    this._hadUserInteraction = false;

    for (let i = 0; i < shareIds.length; i++) {
      const id = shareIds[i];

      if (this._hadUserInteraction = this._hadUserInteraction || this.hasUserInteraction) {
        this.setAllSharesNotActive();

        break;
      }

      setTimeout(() => {
        if (this._hadUserInteraction = this._hadUserInteraction || this.hasUserInteraction) {
          this.setAllSharesNotActive();
  
          return;
        }

        this.setShareAsActive(id);

        setTimeout(() => this.setShareAsNotActive(id), delay);
      }, delay * i);
    }
  }

  private setShareAsActive(id: string) {
    this._chartShares.get(id)?.classList.add("-is-active");
    this._breakdownShares.get(id)?.classList.add("-is-active");
  }

  private setShareAsNotActive(id: string) {
    this._chartShares.get(id)?.classList.remove("-is-active");
    this._breakdownShares.get(id)?.classList.remove("-is-active");
  }

  private setAllSharesNotActive() {
    for (const $share of Array.from(this._chartShares.values()).concat(Array.from(this._breakdownShares.values()))) {
      $share.classList.remove("-is-active");
    }
  }
}

export class Header {
  private _element: HTMLElement;

  public constructor(element: Element | null | undefined) {
    if (!(element instanceof HTMLElement)) { throw new Error(`element: ${element} is not an HTMLElement`); }

    this._element = element;

    document.onscroll = (event) => { this.toggleTransparency(); /* TODO: copy to IntersectionObserver */ }
  }

  public get element(): HTMLElement { return this._element; };

  public get height(): number {
    const $headerInner = this.element.querySelector(".c-header__inner");

    if ($headerInner == null) { throw new Error(`header: ${this.element} does not have any content`); }

    return window.getComputedStyle($headerInner).display !== "none"
      ? Header.parseSize(window.getComputedStyle(this.element).height) || 0
      : 0;
  }

  public static fromObject({ element }: { element: Element | null | undefined }) {
    return new Header(element);
  }

  /**
   * 
   *
   * @param value 
   *
   * @returns 
   */
  private static parseSize(value: string): number | undefined {
    const matches = value.match(/([0-9]+).*/);

    return matches != null
      ? parseFloat(matches[1])
      : undefined;
  }

  private toggleTransparency() {
    const scrollY = window.scrollY != null
      ? window.scrollY
      : window.pageYOffset;

    this.element.classList.toggle("-is-opaque", scrollY > this.element.offsetHeight / 2);
  }
}

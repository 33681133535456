import { Distribution, Header, Navigation } from "./components";

(function() {
  document.documentElement.classList.add("js");

  // TODO: change URL on scroll (intersection observer)
  // TODO: chart hover interaction (fallback for :has)
  // TODO: chart touch interaction
  // TODO: contact form

  const header = new Header(document.body.querySelector(".c-header"));
  const $headerInner = header.element.querySelector(".c-header__inner");

  let navigations = Array.from(document.body.querySelectorAll(".c-nav")).map(($nav) => {
    return new Navigation($nav, { headerHeight: header.height });
  });

  window.onresize = () => {
    // TODO: debounce

    for (const navigation of navigations) {
      navigation.headerHeight = header.height;
    }
  };

  const sectionSelectors = [
    "#home",
    "#about",
    "#how-to-buy",
    "#tokenomics",
    "#roadmap",
    "#contact",
  ];

  const $sections = document.body.querySelectorAll(sectionSelectors.join(","));

  if (document.body.id === "teaser") {
    if (header.element == null) { throw new Error(`body: ${document.body} does not have a nav`); }

    if (window.IntersectionObserver) {
      const observer = new IntersectionObserver(() => {
        
      }, { rootMargin: `${header.height * -1}px`, threshold: 0 });

      for (const $section of Array.from($sections)) {
        observer.observe($section);
      }
    }
  } else {
    const distribution = Distribution.fromObject({
      chart: document.body.querySelector(".c-tokenomics__distribution-chart"),
      breakdown: document.body.querySelector(".c-tokenomics__distribution-breakdown"),
    });

    distribution.playDistributionAffordance(); // TODO: wrap in IntersectionObserver

    if (header.element == null) { throw new Error(`body: ${document.body} does not have a nav`); }

    if (window.IntersectionObserver) {
      const observer = new IntersectionObserver(() => {
        
      }, { rootMargin: `${header.height * -1}px`, threshold: 0 });

      for (const $section of Array.from($sections)) {
        observer.observe($section);
      }
    }
  }
})();

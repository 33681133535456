export class Navigation {
  private _element: HTMLElement;
  private _lastActiveLink: Element | undefined;

  private _headerHeight: number;

  public constructor(element: Element | null | undefined, { headerHeight = 0 }: { headerHeight?: number } = {}) {
    if (!(element instanceof HTMLElement)) { throw new Error(`element: ${element} is not an HTMLElement`); }

    this._element = element;

    this._headerHeight = headerHeight;

    for (const $link of Array.from(this.element.querySelectorAll(".c-nav__link"))) {
      if (!($link instanceof HTMLAnchorElement)) { continue; }

      if (location.href.startsWith($link.href)) {
        this.setLinkAsActive($link);
      }

      $link.onmouseover = this.onLinkMouseOver;

      if ($link.host !== location.host || !$link.href.includes("#")) { continue; }

      $link.onclick = this.onLinkClick;
    }

    this.element.onmouseleave = this.onMouseLeave;
  }

  public get element(): HTMLElement { return this._element; }

  public get headerHeight(): number { return this._headerHeight; }

  public set headerHeight(value: number) { this._headerHeight = value; }

  public static fromObject({
    element, headerHeight = 0,
  }: { element: Element | null | undefined, headerHeight?: number }) {
    return new Navigation(element, { headerHeight });
  }

  public setLinkAsActive(element: Element | null | undefined) {
    if (element == null) { throw new Error(`element: ${element} is not an Element`); }

    this.setLinksAsNotActive();

    element.classList.add("-is-active");
  }

  /**
   * Scrolls to the {@link $element}, optionally with an {@link offset}.
   *
   * @param $element 
   * @param options
   * @param [options.offset]
   */
  private static scrollTo($element: Element, { offset = 0 } = {}) {
    window.scroll({
      top: $element.getBoundingClientRect().top + window.scrollY + offset,
      behavior: "auto",
    });
  }

  private setLinksAsNotActive() {
    for (const $link of Array.from(this.element.querySelectorAll(".c-nav__link"))) {
      if ($link.classList.contains("-is-active")) {
        this._lastActiveLink = $link;
      }

      $link.classList.remove("-is-active");
    }
  }

  private onLinkMouseOver = () => { this.setLinksAsNotActive(); } // lexical this

  private onLinkClick = (event: Event) => { // lexical this
    if (!(event.target instanceof HTMLAnchorElement)) {
      throw new TypeError(`event target: ${event.target} is not an HTMLAnchorElement`);
    }

    if (window.history && location.href !== event.target.href) {
      history.pushState({}, "", event.target.href);
    }

    const $section = document.body.querySelector(new URL(event.target.href).hash);

    if ($section == null) { return; }

    Navigation.scrollTo($section, { offset: -this._headerHeight });

    event.preventDefault();
  }

  private onMouseLeave= () => { // lexical this
    if (this._lastActiveLink == null) { return; }

    this.setLinkAsActive(this._lastActiveLink);
  }
}
